





















import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { MyPageViewModel } from '../../viewmodels/my-page-viewmodel'

@Observer
@Component({
  components: {
    'user-to-follow-item': () => import('@/modules/community/home/components/discovery/user-to-follow-item.vue'),
    observer: () => import('vue-intersection-observer'),
  },
})
export default class UserToFollowList extends Vue {
  @Inject() vm!: MyPageViewModel

  loadMore(entry) {
    if (this.vm.usersCanLoadMore) {
      if (entry.isIntersecting) this.vm.usersLoadMore()
    }
  }

  goBack() {
    this.$router.go(-1)
  }
}
